import './App.css';
import {
  Routes,
  Navigate,
  Outlet,
  Route,
} from "react-router-dom";
import { FactionView } from './components/views/Faction/Factions/Faction';
import { FactionsView } from './components/views/Faction/Factions/Factions';
import { TeamView } from './components/views/Team/Team';
import NavBar from './components/NavBar';
import { Rules } from './components/views/Rules';
import { Contact } from './components/views/Contact';
import { Impressum } from './components/views/Imresspum';
import { PlayView } from './components/views/Play';
import { LeaderboardView } from './components/Leaderboard';
import { useContext } from 'react';
import { authContext } from './contexts/auth';
import HomeView from './pages/views/Home/HomeView';
import MaintenanceView from './pages/views/Auth/Maintenance';
import LoginView from './pages/views/Auth/Login';
import Footer from './components/Footer';
import ControlpanelRoutes from './pages/views/Controlpanel/CPRoutes';

export const server = "https://api.voidroleplay.de";

const App: React.FC = () => {
  return (
    <div>
      <Routes>
        <Route element={<OnlyUnauthRoutes redirectTo='/' />}>
          <Route path='/auth/login' element={<LoginView />} />
        </Route>
        <Route path='/imprint' element={<Impressum />} />
        <Route path='/maintenance' element={<MaintenanceView />} />
        <Route element={<AuthRoutes redirectTo='/maintenance' />}>
          <Route path='/*' element={<MainRoute />} /> {/* Routen unter MainRoute */}
        </Route>
        <Route element={<AuthRoutes redirectTo='/auth/login' />}>
          <Route path='/cp/*' element={<ControlpanelRoutes />} />
        </Route>
      </Routes>
      <Footer />
    </div>
  );
}

const MainRoute = () => {
  return (
    <div>
      <NavBar />
      <Outlet />
      <MainRoutesWithChildRoutes />
    </div>
  );
}

const MainRoutesWithChildRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<HomeView />} />
      <Route path='/leaderboard/:type' element={<LeaderboardView />} />
      <Route path="/factions/:faction" element={<FactionView />} />
      <Route path='/factions' element={<FactionsView />} />
      <Route path='/team' element={<TeamView />} />
      <Route path='/play' element={<PlayView />} />
      <Route path='/rules' element={<Rules />} />
      <Route path='/contact' element={<Contact />} />
    </Routes>
  );
}

interface IFunctionRoute {
  redirectTo: string;
}

const AuthRoutes = ({ redirectTo }: IFunctionRoute) => {
  if (!redirectTo) {
    throw new Error("<AuthRoutes />: prop 'redirectTo' is required!");
  }

  const { currentUser }: any = useContext(authContext);
  return currentUser ? <Outlet /> : <Navigate to={redirectTo} />;
}

const OnlyUnauthRoutes = ({ redirectTo }: IFunctionRoute) => {
  if (!redirectTo) {
    throw new Error("<OnlyUnauthRoutes/>: prop 'redirectTo' is required!");
  }

  const { currentUser }: any = useContext(authContext);
  return currentUser ? <Navigate to={redirectTo} /> : <Outlet />;
}

export default App;
