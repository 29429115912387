import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import GroupIcon from '@mui/icons-material/Group';
import BusinessIcon from '@mui/icons-material/Business';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import PublicIcon from '@mui/icons-material/Public';
import ForumIcon from '@mui/icons-material/Forum';
import { server } from "../../../App";
import { formatNumber } from "../../../components/api/Utils";
import { CenteredLoader } from "../../../components/views/Utils";

const HomeView = () => {
    const [statistics, setStatistics] = useState<any>();
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        const load = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${server}/statistics`, {
                    method: 'GET',
                    mode: 'cors',
                });
                const companyData = await response.json();
                setStatistics(companyData);
            } catch (error) {
                console.error("Error loading statistics:", error);
            } finally {
                setLoading(false);
            }
        }
        load();
    }, []);

    return (
        <>
            {statistics && !isLoading ? (
                <div className="flex flex-col min-h-screen bg-gray-100 dark:bg-gray-900 bg-backgroundhome">
                    <main className="flex-1">
                        {/* Hero Section */}
                        <section className="relative flex items-center justify-center w-full min-h-screen py-12 md:py-24 lg:py-32 bg-cover bg-center" style={{ backgroundImage: `url('/images/background.jpg')` }}>
                            <div className="absolute inset-0 bg-black opacity-50"></div>
                            <div className="container px-4 md:px-6 text-center text-white relative z-10">
                                <h1 className="text-5xl font-extrabold tracking-tight">
                                    Willkommen auf Void Roleplay!
                                </h1>
                                <p className="mx-auto max-w-[700px] text-xl mt-4">
                                    Entdecke ein einzigartiges Reallife & Roleplay Abenteuer.
                                </p>
                                <div className="mt-8">
                                    <Link to="/play" className="px-8 py-4 bg-orange-500 text-white font-semibold rounded-md hover:bg-orange-600 transition duration-300 ease-in-out inline-block">
                                        Jetzt spielen
                                    </Link>
                                </div>
                            </div>
                        </section>

                        {/* Statistics Section */}
                        <section className="flex items-center justify-center w-full py-12 md:py-24 lg:py-32 bg-gray-900 text-white">
                            <div className="container px-4 md:px-6 text-center">
                                <p className="text-lg">
                                    Trete unserer lebendigen Minecraft-Community bei und wähle aus <strong className="text-orange-400">{formatNumber(statistics.factionCount)}</strong> spannenden Fraktionen oder werde Teil eines aufstrebenden Unternehmensimperiums mit <strong className="text-orange-400">{formatNumber(statistics.companyCount)}</strong> Unternehmen.
                                </p>
                            </div>
                        </section>

                        {/* Features Section */}
                        <section className="flex flex-col items-center justify-center w-full py-12 md:py-24 lg:py-32 bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-white">
                            <div className="container px-4 md:px-6 text-center">
                                <h2 className="text-4xl font-bold mb-8">Warum Void Roleplay?</h2>
                                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                                    <div className="p-6 bg-white dark:bg-gray-700 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out">
                                        <GroupIcon className="mx-auto text-5xl text-orange-500 mb-4" />
                                        <h3 className="text-2xl font-semibold mb-2">Aktive Community</h3>
                                        <p className="text-gray-700 dark:text-gray-300">Schließe dich hunderten von Spielern an und erlebe ein dynamisches und lebendiges Spielerlebnis.</p>
                                    </div>
                                    <div className="p-6 bg-white dark:bg-gray-700 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out">
                                        <BusinessIcon className="mx-auto text-5xl text-orange-500 mb-4" />
                                        <h3 className="text-2xl font-semibold mb-2">Unternehmen</h3>
                                        <p className="text-gray-700 dark:text-gray-300">Gründe und manage dein eigenes Unternehmen im Spiel und erlebe wirtschaftliche Abenteuer.</p>
                                    </div>
                                    <div className="p-6 bg-white dark:bg-gray-700 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out">
                                        <EmojiEventsIcon className="mx-auto text-5xl text-orange-500 mb-4" />
                                        <h3 className="text-2xl font-semibold mb-2">Fraktionen</h3>
                                        <p className="text-gray-700 dark:text-gray-300">Schließe dich einer Fraktion an und kämpfe um die Vorherrschaft in der Welt von Void Roleplay.</p>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* Community Section */}
                        <section className="flex items-center justify-center w-full py-12 md:py-24 lg:py-32 bg-gray-900 text-white">
                            <div className="container px-4 md:px-6 text-center">
                                <h2 className="text-4xl font-bold mb-8">Unsere Community</h2>
                                <p className="text-lg max-w-[800px] mx-auto mb-8">Unsere Community ist das Herz von Void Roleplay. Wir bieten eine freundliche, hilfsbereite und aktive Gemeinschaft von Spielern, die alle dasselbe Ziel teilen: Spaß haben und epische Abenteuer erleben.</p>
                                <div className="flex justify-center space-x-4">
                                    <Link to="https://discord.me/voidrp" className="px-8 py-4 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600 transition duration-300 ease-in-out inline-block flex items-center space-x-2">
                                        <ForumIcon className="text-xl" />
                                        <span>Discord beitreten</span>
                                    </Link>
                                    <Link to="https://forum.voidroleplay.de" className="px-8 py-4 bg-gray-700 text-white font-semibold rounded-md hover:bg-gray-600 transition duration-300 ease-in-out inline-block flex items-center space-x-2">
                                        <ForumIcon className="text-xl" />
                                        <span>Zum Forum</span>
                                    </Link>
                                </div>
                            </div>
                        </section>

                        {/* Global Section */}
                        <section className="flex items-center justify-center w-full py-12 md:py-24 lg:py-32 bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-white">
                            <div className="container px-4 md:px-6 text-center">
                                <h2 className="text-4xl font-bold mb-8">Du und Void Roleplay</h2>
                                <p className="text-lg max-w-[800px] mx-auto mb-8">Void Roleplay lebt von seiner Community und so handeln wir. Du hast eine Idee? Sag Sie uns und wir versuchen diese umzusetzen! Dich stört etwas? Sag uns das und wir ändern es!</p>
                                <div className="flex justify-center space-x-4">
                                    <PublicIcon className="text-5xl text-orange-500" />
                                </div>
                            </div>
                        </section>
                    </main>
                </div>
            ) : (
                <div className="flex items-center justify-center h-screen bg-gray-100 dark:bg-gray-900">
                    <CenteredLoader />
                </div>
            )}
        </>
    )
}

export default HomeView;