import { Link } from "react-router-dom";
import { Box, Typography } from '@mui/material';
import GavelIcon from '@mui/icons-material/Gavel'; // For Impressum
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip'; // For Datenschutzerklärung
import ContactPageIcon from '@mui/icons-material/ContactPage'; // For Kontakt
import ForumIcon from '@mui/icons-material/Forum'; // For Forum
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'; // For Shop
import GroupIcon from '@mui/icons-material/Group'; // For Team
import InterpreterModeIcon from '@mui/icons-material/InterpreterMode';

const Footer = () => {
    return (
        <footer className="bg-gradient-to-r bg-gray-900 dark:bg-gray-800 flex flex-col sm:flex-row py-6 w-full border-t border-gray-400">
            <Typography
                variant="body2"
                className="text-xs text-center sm:text-left text-gray-100 dark:text-gray-400 px-4 md:px-6 mb-4 sm:mb-0"
            >
                Void Roleplay steht nicht in Verbindung zu Mojang, Minecraft oder Microsoft.
            </Typography>
            <Box className="flex flex-wrap justify-center sm:justify-end gap-4 sm:gap-6 sm:ml-auto sm:mr-4">
                <Link to="/imprint" className="text-xs flex items-center gap-2 hover:underline underline-offset-2 text-gray-100 dark:text-gray-400">
                    <GavelIcon className="text-base" /> Impressum
                </Link>
                <Link to="/privacy-policy" className="text-xs flex items-center gap-2 hover:underline underline-offset-2 text-gray-100 dark:text-gray-400">
                    <PrivacyTipIcon className="text-base" /> Datenschutzerklärung
                </Link>
                <Link to="/contact" className="text-xs flex items-center gap-2 hover:underline underline-offset-2 text-gray-100 dark:text-gray-400">
                    <ContactPageIcon className="text-base" /> Kontakt
                </Link>
                <Link to="https://shop.voidroleplay.de" className="text-xs flex items-center gap-2 hover:underline underline-offset-2 text-gray-100 dark:text-gray-400">
                    <ShoppingCartIcon className="text-base" /> Shop
                </Link>
                <Link to="/team" className="text-xs flex items-center gap-2 hover:underline underline-offset-2 text-gray-100 dark:text-gray-400">
                    <GroupIcon className="text-base" /> Team
                </Link>
                <Link to="https://discord.me/voidrp" className="text-xs flex items-center gap-2 hover:underline underline-offset-2 text-gray-100 dark:text-gray-400">
                    <InterpreterModeIcon className="text-base" /> Discord
                </Link>
            </Box>
        </footer>
    );
};

export default Footer;