import { useState } from "react";
import Label from "../../../components/Label";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import { server } from "../../../App";
import { useNavigate } from "react-router-dom";
import LoadingBar from "../../../components/LoadingBar";

const LoginView = () => {
    const [form, setForm] = useState({ user: '', password: '' });
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    let handleLogin = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        const response = await fetch(`${server}/account/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            body: JSON.stringify(form),
        });

        if (response.ok) {
            const data = await response.json();
            setForm({ user: '', password: '' });
            localStorage.setItem('user', JSON.stringify(data));
            navigate('/');
        }
        setLoading(false);
    };


    const handleChange = (e: any) => {
        setForm({ ...form, [e.target.name]: e.target.value })
    }

    return (
        <>
            <LoadingBar
                active={loading}
            />

            <div className="flex items-center bg-gray-900 justify-center min-h-screen">
                <div className="w-full max-w-md p-6 bg-gray-800 bg-opacity-80 rounded-lg shadow-lg border-2 border-gray-700">
                    <h2 className="text-3xl font-bold text-center text-orange-500 mb-6">Void Roleplay</h2>
                    <form onSubmit={handleLogin} className="space-y-4">
                        <div className="space-y-2">
                            <Label>Spielername</Label>
                            <Input
                                id="name"
                                type="text"
                                placeholder="Notch"
                                name="user"
                                value={form.user}
                                onChange={handleChange}
                                className="w-full px-3 py-2"
                                required
                                disabled={loading}
                            />
                        </div>
                        <div className="space-y-2">
                            <Label>Passwort</Label>
                            <Input
                                id="password"
                                type="password"
                                placeholder="••••••"
                                value={form.password}
                                name="password"
                                onChange={handleChange}
                                className="w-full px-3 py-2 bg-gray-700 text-white border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                                required
                                disabled={loading}
                            />
                        </div>
                        <Button
                            type="submit"
                            className="w-full"
                            disabled={loading}
                        >
                            Login
                        </Button>
                    </form>
                </div>
            </div>
        </>
    )
}

export default LoginView;